/* DisplayItem
 *
 *  A single display item. For use with DisplayItemList
 *
 *  Props (all optional):
 *    name
 *    title (use for html in title, defaults to name text)
 *    image
 *    primaryInfo
 *    description
 *    details: (connects item to an ItemDetails modal) map:
 *      itemId: (required slug)
 *      product: (required if using the shopping cart)
 *      detailsContent: Some JSX. If not there will use primaryInfo and description
 *    purchase
 */

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Breakpoints } from "../util/breakpoints"

import Thumbnail from "./thumbnail"

// For item details
import ItemDetails from "./item-details"
import Modal from "./modal"
import ModalLink from "./modal-link"

// For purchasing
import PurchaseActions from "./purchase-actions"

const StyledDisplayItem = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: ${props => props.image ? 'min-content auto' : 'auto'};
  grid-column-gap: 15px;
  grid-template-areas: ${props => props.image && props.purchase ?
      '"image info " "actions actions" "description description"' :
    props.image && !props.purchase ?
      '"image info " "description description"' :
    !props.image && props.purchase ?
      '"info" "actions" "description"' :
    //!props.image && !props.purchase
      '"info" "description"'
  };

  @media ${Breakpoints.smallOrLarger} {
    grid-template-rows: ${props => props.image ? 'min-content' : 'auto'};
    grid-template-areas: ${props => props.image && props.purchase ?
        '"image info " "image actions" "image description"' :
      props.image && !props.purchase ?
        '"image info " "image description"' :
      !props.image && props.purchase ?
        '"info" "actions" "description"' :
      //!props.image && !props.purchase
        '"info" "description"'
    };
    margin-bottom: 15px;
    row-gap: 0;
    border: 0;
  }
`

const DisplayImage = styled.div`
  grid-area: image;
  width: max(100px, min(25vw, 300px));
  height: max(100px, min(25vw, 300px));

  @media ${Breakpoints.largeOrLarger} {
    width: 200px;
    height: 200px;
  }
`

const ImageBox = styled.div`
  width: 100%;
  height: 100%;
`

const ItemTitle = styled.h3`
  margin: 0

  a:link {
    margin: 0
  }

  @media ${Breakpoints.smallOrLarger} {
    margin-top: 15px;
  }
`

const DisplayInfo = styled.div`
  grid-area: info;
  display: grid;
  grid-template-coluns: auto;
  width: 100%;
`

const PrimaryInfo = styled.div`
  @media ${Breakpoints.smallOrLarger} {
    align-self: center;
  }
`

const DisplayDescription = styled.div`
  grid-area: description;
  padding-top: 10px; 
  padding-bottom: 20px; 

  @media ${Breakpoints.smallOrLarger} {
    padding-bottom: 0;
    align-self: center;
  }
`

const Actions = styled.div`
  grid-area: actions;
  margin-top: 10px;
`

const thumbnailSize = {
  // Shrinks down to 100px as the viewport gets small
  // Otherwise, fills its container up to 300px
  // This allows us to shrink the container responsively
  // even though we can't set the size responsively
  height: "max(100px, min(25vw, min(100%, 300px)))",
  width: "max(100px, min(25vw, min(100%, 300px)))",
}

const validateItemDetails = (name, details, purchase) => {
  const slugRegex = /^[a-zA-Z0-9]+[a-zA-Z0-9_-]*[a-zA-Z0-9]+$/
  if (!!details) {
    const itemId = details.get("itemId")
    if (!itemId) {
      console.error(`Error in DisplayItem for "${name}": itemId required to show details`)
    } else if (!slugRegex.test(itemId)) {
      console.error(`Error in DisplayItem for "${name}": itemId ${itemId} must be a slug`)
    }
    if (purchase && !details.get("product")) {
      console.error(`Error in DisplayItem for "${name}": purchaseable item requires product data`)
    }
  }
}

export default function DisplayItem(props) {
  validateItemDetails(props.name, props.details, props.purchase)

  const nameJSX = props.link ?
    <ItemTitle data-qa={"ItemTitle"}>
      <Link to={props.link}>
        {props.name}
      </Link>
    </ItemTitle> :
    props.details ? (
      <ItemTitle id={props.details.get("itemId")} data-qa={"ItemTitle"}>
        <ModalLink modalId={props.details.get("itemId")}>
          {props.name}
        </ModalLink>
      </ItemTitle>
    ) :
    <ItemTitle data-qa={"ItemTitle"}>{props.name}</ItemTitle>

  const imageJSX = props.link ?
    <DisplayImage data-qa={"ItemImage"}>
      <Link to={props.link}>
        <ImageBox>
          <Thumbnail containerStyle={thumbnailSize} src={props.image} />
        </ImageBox>
      </Link>
    </DisplayImage> :
    props.details ?
    <DisplayImage data-qa={"ItemImage"}>
      <ModalLink modalId={props.details.get("itemId")} >
        <ImageBox>
          <Thumbnail
            containerStyle={thumbnailSize}
            src={props.image} />
        </ImageBox>
      </ModalLink>
    </DisplayImage> :
    <DisplayImage data-qa={"ItemImage"}>
      <ImageBox>
        <Thumbnail
          size={thumbnailSize}
          src={props.image} />
      </ImageBox>
    </DisplayImage>

  const detailsContent = !props.details ? null :
    props.details.has("detailsContent") ? props.details.get("detailsContent") :
    (<>
      {props.primaryInfo && <PrimaryInfo>{props.primaryInfo}</PrimaryInfo>}
      {props.description && <DisplayDescription>{props.description}</DisplayDescription>}
    </>)

  return (
    <>
      {(!props.link && props.details) && (
        <Modal
          id={props.details.get("itemId")}
          contentLabel={props.name}
          data-qa={"ItemDetailsModal"}
        >
          <ItemDetails
            title={props.name}
            src={props.image}
            longDescription={detailsContent}
          />
        </Modal>
      )}

      <StyledDisplayItem
        image={!!props.image}
        purchase={!!props.purchase}
        data-qa={"DisplayItem"}
      >
        {props.image && imageJSX}

        <DisplayInfo>
          {nameJSX}
          {props.primaryInfo && (
            <PrimaryInfo data-qa={"ItemPrimaryInfo"}>
              {props.primaryInfo}
            </PrimaryInfo>
          )}
        </DisplayInfo>

        {props.description && (
          <DisplayDescription data-qa={"ItemDescription"}>
            {props.description}
          </DisplayDescription>
        )}

        {props.purchase && (
          <Actions>
            <PurchaseActions
              name={props.name}
              product={props.details.get("product")}
            />
          </Actions>
        )}

      </StyledDisplayItem>
    </>
  )
}
