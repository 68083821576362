/* Products
 *
 *  Products component that takes in data
 *
 *  Props:
 *    purchase (defaults to global setting): Boolean for including purchase actions
 *      If using local products and selling via stripe, product IDs must match Stripe
 *
 *  Examples:
 *    <Products />
 *    <Products purchase={true} />
 */

import React from "react"
import { formatCurrencyString } from "../util/currency"
import useUserPreferences from "../hooks/use-user-preferences"

import DisplayItemList from "./display-item-list"

export default function Products(props) {

  const globalPurchaseSetting = useUserPreferences().purchase
  const purchase = props.purchase !== undefined ? props.purchase : globalPurchaseSetting

  const productDisplayData = product => new Map(Object.entries({
    link: `/items/${product.id}/`,
    primaryInfo: product.price >= 0 ?
      formatCurrencyString(product.price, product.currency) :
      undefined,
    description: <i>{product.description}</i>,
    image: product.images.length > 0 ?
      product.images[0].src || product.images[0] :
      undefined,
    details: new Map(Object.entries({
      itemId: product.id,
      detailsContent: <i>{product.description}</i>,
      // product data is required for the shopping cart
      product: product,
    })),
  }))

  const items = props.items || []
  let displayProductList = []
  for (const product of items) {
    displayProductList.push([product.name, productDisplayData(product)])
  }
  // Lexicographic sort by name
  displayProductList.sort( (p1, p2) =>
    p1[0].toLowerCase().localeCompare(p2[0].toLowerCase())
  )

  return (
    <DisplayItemList
      items={new Map(displayProductList)}
      purchase={purchase}
      data-qa={props["data-qa"] || "ProductList"}
    />
  )
}
