/* LocalProducts
 *
 *  Fetches data and passes it to the products component
 *
 *  Props:
 *    purchase (defaults to global setting): Boolean for including purchase actions
 *      If using local products and selling via stripe, product IDs must match Local
 *    filter (optional): function taking in a product (map) and returning a Boolean
 *
 *  Examples:
 *    <LocalProducts />
 *    <LocalProducts
 *      purchase={true}
 *      filter={product => product.keywords.includes("entree")}
 *    />
 */

import React from "react"
import useLocalItems from "../hooks/use-local-items"

import Products from "./products"

export default function LocalProducts(props) {

  const items = useLocalItems()
  const filteredItems = items.filter(props.filter)

  return (
    <Products
      purchase={props.purchase}
      items={filteredItems}
      data-qa={props["data-qa"] || "LocalProducts"}
    />
  )
}

LocalProducts.defaultProps = {
  filter: product => true,
}
